<script>
  export default {
    name: 'MWParser',
    data: () => ({
      savedURLs: [],
      snackbar: {
        color: 'success',
        message: '',
        show: false,
      },
      scrollPos: 0,
    }),
    methods: {
      onScroll(e) {
        this.scrollPos = e?.target?.scrollingElement?.scrollTop
      },
      scrollTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      },
      triggerSnackbar(values = {}) {
        const defaultValues = {
          color: 'success',
          message: '',
          show: Boolean(values.message),
        }
        const snackbarValues = { ...defaultValues, ...values }
        this.snackbar = snackbarValues
      },
    },
  }
</script>

<template>
  <div id="app">
    <v-app>
      <v-main v-scroll="onScroll">
        <v-container class="main-container d-flex flex-column align-center">
          <div class="header">
            <img alt="Madewell logo" src="./assets/logo.png" class="logo" />
            <h2 class="subtext">Parser</h2>
          </div>
          <router-view @snackbar="triggerSnackbar" />
          <v-fab-transition>
            <v-btn v-show="scrollPos > 500" color="primary" dark fixed bottom right fab @click="scrollTop" class="mr-4 mb-4">
              <v-icon large>mdi-chevron-up</v-icon>
            </v-btn>
          </v-fab-transition>
          <v-snackbar :value="snackbar.show" bottom center :color="snackbar.color" dissmi @input="triggerSnackbar"
            ><v-icon class="mr-4">mdi-information</v-icon> <span class="font-weight-bold">{{ snackbar.message }}</span></v-snackbar
          >
        </v-container>
      </v-main>
    </v-app>

    <!-- <nav> -->
    <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->
    <!-- </nav> -->
    <!-- 

    scrollTop() {
      //todo: create content element where this
      //button is attached to. dont prefer using watchers
      this.$store.dispatch('setScrollElementPos', 0)
      return
      if (this.$store.state.scrollElement) {
        this.$store.state.scrollElement.scrollTo({ top: 0, behavior: 'smooth' })
      }
    },

 -->
  </div>
</template>

<style>
  :root {
    --primary-color: #6868c8;
    --secondary-color: #ffffff;
  }

  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  .full-width {
    width: 100%;
  }

  .header {
    width: clamp(10rem, 20rem, 100vw);
    min-width: 220px;
    position: relative;
    display: inline-block;
    padding-top: 1rem;
    margin-bottom: 2rem;
  }

  .logo {
    max-width: 100%;
    height: auto;
  }

  .subtext {
    font-family: 'Marck Script', cursive;
    font-size: clamp(2rem, 3rem, 100vw);
    letter-spacing: 0.025ch;
    font-weight: bold;
    color: #6868c8;
    position: absolute;
    bottom: -30px;
    left: 20px;
  }

  .pointer {
    cursor: pointer;
  }

  .main-container {
    /* max-width: 900px; */
    margin: 0;
    padding: 0;
    min-width: 100%;
  }

  /* MODALS */
  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
</style>
