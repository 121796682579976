<script>
export default {
  name: 'product-item',
  props: {
    product: {
      type: Object,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: () => true,
    },
  },
  computed: {},
  methods: {
    onImgLoad() {
      // console.log(e)
    },
  },
  created() {},
}
</script>

<template>
  <v-list-item class="text-left">
    <v-list-item-avatar
      size="50"
      @click="$emit('showImage', product)"
      class="pointer"
    >
      <v-img :src="product.imageUrl" @load="onImgLoad"></v-img>
    </v-list-item-avatar>
    <v-list-item-content>
      <v-list-item-title class="primary--text font-weight-bold">
        <a
          v-html="product.name"
          :href="product.monetateProducturl"
          rel="noopener"
          target="_blank"
          :title="product.name"
        ></a>
      </v-list-item-title>
      <v-list-item-subtitle
        v-html="product.id"
        class="font-weight-bold"
      ></v-list-item-subtitle>
      <v-list-item-subtitle
        v-html="product.priceDisplayed"
        class="success--text"
      ></v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action
      v-if="showActions"
      class="d-flex flex-row ma-0 mt-auto pb-2"
    >
      <v-tooltip color="primary" outlined top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="$emit('showDetails', product)">
            <v-icon color="primary">mdi-format-list-bulleted</v-icon>
          </v-btn>
        </template>
        <span class="secondary--text">Show Details</span>
      </v-tooltip>

      <v-tooltip color="primary" outlined top>
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on" @click="$emit('showImage', product)">
            <v-icon color="primary">mdi-image</v-icon>
          </v-btn>
        </template>
        <span class="secondary--text">View Image</span>
      </v-tooltip>

      <v-tooltip color="primary" outlined top>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
            :href="product.monetateProducturl"
            rel="noopener"
            target="_blank"
          >
            <v-icon color="primary">mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <span class="secondary--text">Open product page</span>
      </v-tooltip>
    </v-list-item-action>
  </v-list-item>
</template>

<style scoped></style>
