const authcode = localStorage.getItem('code')
// const SCRAPER_URL = 'http://mwparser.com:3000/'
const SCRAPER_URL = 'https://api.mwparser.com/'
// const SCRAPER_URL = 'http://localhost:3000/'
// const SCRAPER_URL = 'http://localhost:8000/'
// const SCRAPER_URL = 'http://192.168.19.100:3000/'
const get = async (uri, params = null) => {
  if (!uri) return
  const url = new URL(SCRAPER_URL + uri)
  if (Array.isArray(params)) {
    params.forEach(p => {
      url.searchParams.append(p.key, p.value)
    })
  }
  const reqHeaders = new Headers({
    auth: authcode,
  })
  const res = await fetch(url, { headers: reqHeaders })
  const json = await res.json()
  return json
}

export default { get }
