<script>
  export default {
    name: 'url-edit',
    props: {
      searchData: {
        type: Object,
        default: () => ({}),
      },
      savedSearches: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      message: null,
      form: {
        id: '',
        title: '',
        url: '',
      },
      isLoading: false,
      modalConfirmDelete: false,
    }),
    computed: {},
    methods: {
      deleteUrl() {
        this.$emit('deleteSearch', this.form.id)
      },
      saveSearch() {
        this.message = null
        this.isLoading = true
        const matches = this.savedSearches.filter(i => {
          return i.id !== this.id && (i.title === this.title || i.url === this.url)
        })
        if (matches.length) {
          if (matches[0].title === this.title) this.message = 'A saved search already has this title.'
          if (matches[0].url === this.url) this.message = `This URL is already saved as "${matches[0].title}".`
          this.isLoading = false
          return
        }
        this.$emit('saveSearch', this.form)
      },
    },
    created() {
      if (this.searchData) {
        this.form = { ...this.searchData }
      }
    },
  }
</script>

<template>
  <v-card max-width="600px" width="100vw">
    <v-btn color="primary" icon @click="$emit('close')" class="close-btn"> <v-icon color="">mdi-close</v-icon> </v-btn>

    <v-card-title class="primary--text text-h4 justify-center"> {{ form.id ? 'EDIT' : 'SAVE' }} SEARCH </v-card-title>
    <v-card-text class="d-flex flex-column align-center justify-center pb-0 pt-4">
      <v-text-field v-model="form.title" autofocus color="primary" label="title" name="title" outlined class="full-width" />
      <v-textarea v-model="form.url" color="primary" label="url" rows="3" name="url" outlined class="full-width" />
      <v-sheet color="transparent" height="100px" width="100%">
        <v-alert v-if="message" type="error" class="text-left">{{ message }}</v-alert>
      </v-sheet>
    </v-card-text>
    <v-card-actions>
      <v-btn color="warning" text @click="$emit('close')" class="font-weight-bold"> <v-icon left>mdi-delete</v-icon>CANCEL </v-btn>
      <v-btn color="error" text @click="deleteUrl" class="font-weight-bold ml-2"> <v-icon left>mdi-delete</v-icon>DELETE </v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" :loading="isLoading" text @click="saveSearch" class="font-weight-bold"> <v-icon left>mdi-content-save</v-icon>SAVE </v-btn>
    </v-card-actions>
  </v-card>
</template>

<style scoped></style>
