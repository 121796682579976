<script>
  import ProductList from '@/components/ProductList.vue'
  import UrlEdit from '@/components/url-edit.vue'

  import api from '@/services/api.js'

  export default {
    name: 'HomePage',
    components: { ProductList, UrlEdit },
    data: () => ({
      isLoading: false,
      formValid: true,
      lastSearch: '',
      loadingTimeRemaining: null,
      modalEditUrl: false,
      message: '',
      pageMeta: {},
      savedSearchEditing: null,
      savedSearches: [],
      searchResults: null,
      urlInput: '',
      urlInputSearch: '',
    }),
    computed: {
      submitDisabled() {
        return !this.urlInput
      },
    },
    methods: {
      savedSearchEdit(searchData) {
        this.savedSearchEditing = searchData
        this.modalEditUrl = true
      },
      savedSearchDelete(id) {
        const savedSearches = [...this.savedSearches.filter(s => s.id !== id)]
        this.savedSearches = savedSearches
        localStorage.setItem('savedSearches', JSON.stringify(savedSearches))
        this.modalEditUrl = false
        this.$emit('snackbar', { message: 'Search item deleted!' })
      },
      savedSearchUpsert(search = {}, showAlert = true) {
        if (!search.title || !search.url) {
          console.error('cant save search. missing title or url')
          console.log(search)
          return false
        }
        if (!search.id) {
          search.id = new Date().getTime()
        }

        if (search.resultsCount) delete search.resultsCount
        this.pageMeta = { ...this.pageMeta, ...search }
        const savedSearches = [...this.savedSearches.filter(s => s.id !== search.id), search]
        this.savedSearches = savedSearches
        localStorage.setItem('savedSearches', JSON.stringify(savedSearches))
        this.modalEditUrl = false
        if (showAlert) {
          this.$emit('snackbar', { message: 'Search saved!' })
        }
      },
      inputFilter(item, query) {
        return item.title.toLowerCase().includes(query.toLowerCase()) || item.url.toLowerCase().includes(query.toLowerCase())
      },
      inputRules(input = '') {
        const urlInput = !input ? '' : typeof input === 'string' ? input : input.url
        return urlInput?.includes('madewell.com') || 'Enter a valid madewell url'
      },
      async onSubmit() {
        const isValid = this.$refs.form.validate()
        if (!isValid) {
          return
        }
        this.isLoading = true
        this.lastSearch = ''
        this.message = ''
        this.pageMeta = null
        this.searchResults = null
        const url = this.urlInput?.url || this.urlInput
        try {
          const pageMeta = await api.get('scrape-page-meta', [
            { key: 'page', value: url },
            { key: 'keepalive', value: true },
          ])

          if (!pageMeta || pageMeta.error) {
            const message = pageMeta?.error || 'Error getting page data.'
            console.log(message)
            throw Error(message)
          }
          const pageMetaFixed = this.setPageMeta(pageMeta)
          const existingSearch = this.savedSearches.find(s => s.url === pageMeta.url)
          this.savedSearchUpsert(pageMetaFixed, existingSearch === undefined)
        } catch (e) {
          console.log(e)
          console.log(e.message)
          this.message = e?.message || 'Error finding items on requested page.'
          this.isLoading = false
          return
        }
        this.searchResults = await api.get('scrape-page-items', [{ key: 'page', value: url }])
        this.lastSearch = url
        this.isLoading = false
        if (this.searchResults) {
          await this.$nextTick()
          this.$refs.productList?.$el?.scrollIntoView({ behavior: 'smooth' })
        }
      },
      onUrlInputClear() {
        this.urlInput = ''
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
        this.message = null
      },

      setPageMeta(pageMeta) {
        const savedSearch = this.savedSearches.find(s => s.url === pageMeta.url)
        if (savedSearch) pageMeta = { ...pageMeta, ...savedSearch }
        this.pageMeta = { ...pageMeta }
        return { ...pageMeta }
      },
    },
    created() {
      this.savedSearches = JSON.parse(localStorage.getItem('savedSearches'))?.sort((a, b) => a.title.localeCompare(b.title)) || []
      // this.isLoading = true
      // this.pageMeta = {
      //   title: 'Example title',
      //   resultsCount: 20,
      //   url: '',
      // }
      // this.loadingTimeRemaining = 100
      // const tps = 100 / (this.pageMeta.resultsCount * 5) //total est seconds
      // console.log(tps)
      // const intId = setInterval(() => {
      //   this.loadingTimeRemaining -= tps
      //   console.log(this.loadingTimeRemaining)
      //   if (this.loadingTimeRemaining < 1 || !this.isLoading) {
      //     clearInterval(intId)
      //   }
      // }, 1000)
    },
  }
</script>
<template>
  <v-sheet color="transparent" class="d-flex flex-column align-center justify-center full-width">
    <v-dialog v-model="modalEditUrl" width="auto" max-width="600" overlay-opacity=".96">
      <url-edit
        v-if="modalEditUrl"
        :searchData="savedSearchEditing"
        :savedSearches="savedSearches"
        @close="modalEditUrl = false"
        @deleteSearch="savedSearchDelete"
        @saveSearch="savedSearchUpsert"
      ></url-edit>
    </v-dialog>
    <v-card tile flat max-width="900px" width="100%" class="mb-4">
      <v-card-text class="pb-0">
        <v-form v-model="formValid" ref="form" autocomplete="on" @submit.prevent="onSubmit" class="d-flex align-start justify-center full-width mb-2">
          <v-combobox
            v-model="urlInput"
            clearable
            :filter="inputFilter"
            :hide-selected="false"
            :items="savedSearches"
            item-text="url"
            label="Webpage address"
            name="searchUrl"
            outlined
            primary
            required
            :return-object="true"
            :rules="[inputRules]"
            type="text"
            @click:clear="onUrlInputClear"
            @update:search-input="event => (urlInput = event)"
            class="combobox"
          >
            <template v-slot:item="{ item }">
              <v-list-item-content>
                <v-list-item-title class="text-left">{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle class="text-left">{{ item.url }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="d-flex flex-row">
                <!-- <v-btn
                      color="warning"
                      icon
                      @click.stop.prevent="savedSearchDelete(item.id)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn> -->
                <v-tooltip color="primary" top>
                  <template v-slot:activator="{ on }">
                    <v-btn v-on="{ on }" color="error" icon small @click.stop.prevent="savedSearchDelete(item.id)">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Remove from saved searches</span>
                </v-tooltip>
              </v-list-item-action>
            </template>
          </v-combobox>
          <v-btn color="primary" fab :loading="isLoading" role="submit" small type="submit" class="font-weight-bold ml-2 mt-2">
            <v-icon>mdi-send</v-icon>
          </v-btn>
        </v-form>
        <v-alert v-if="message" type="error" width="100%" class="text-left my-2">
          <!--  <span class="font-weight-bold">  Warning: </span>Received less results than reported by page. -->
          {{ message }}
        </v-alert>
        <!-- max-width="600" -->
        <v-card width="100%" v-if="!message && !searchResults && !isLoading">
          <!-- <v-card width="100%" v-if="!urlInput && !searchResults && !isLoading"> -->
          <v-card-text class="d-flex justify-center primary--text text-headline text-h6">
            <p class="mb-0">Enter the full URL of any Madewell page containing a list of products.</p>
          </v-card-text>
        </v-card>
        <div v-if="isLoading" class="d-flex flex-column align-center justify-center">
          <div v-if="pageMeta && pageMeta.resultsCount">
            <a :href="pageMeta.url" target="_blank" class="font-weight-bold">
              {{ pageMeta.title }}
            </a>
            <p>Fetching {{ pageMeta.resultsCount }} items.</p>
          </div>
          <div v-if="loadingTimeRemaining" style="width: 300px">
            x
            <v-progress-linear :value="loadingTimeRemaining" color="primary" height="25"></v-progress-linear>
          </div>
          <div v-else>
            <span class="primary--text font-weight-bold">Getting page info...</span>
            <v-progress-linear color="primary" indeterminate size="50" class="mb-4"></v-progress-linear>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <template>
      <product-list v-if="!isLoading && searchResults" ref="productList" :pageMeta="pageMeta" :products="searchResults" @edit="savedSearchEdit"></product-list>
    </template>
  </v-sheet>
</template>

<style scoped>
  .combobox {
    z-index: 11;
  }

  /* >>> .v-list-item__action {
  display: none;
  visibility: hidden;
}

.v-list-item:hover >>> .v-list-item__action {
  display: flex;
  visibility: visible;
} */
</style>
